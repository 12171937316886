import ProductItem from './product-item.vue';
export default {
  components: {
    ProductItem: ProductItem
  },
  props: {
    product: {}
  },
  methods: {
    play: function play() {
      this.$emit('play');
    },
    like: function like() {
      this.$emit('like', this.product);
    },
    hover: function hover(product, rect, flag) {
      this.$emit('hover', product, rect, flag);
    }
  }
};