var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p-wrapper",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.play.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "name"
  }, [_c('div', [_vm.product.userReceiveStatus != 1 ? _c('b', {
    staticStyle: {
      "margin-left": "10px",
      "font-weight": "400"
    }
  }, [_vm._v("看视频返"), _c('label', {
    staticStyle: {
      "color": "#E5432E",
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.product.moneyAmount) + "元")]), _vm._v("红包")]) : _vm._e(), _vm.product.userReceiveStatus == 1 ? _c('b', {
    staticStyle: {
      "margin-left": "10px",
      "font-weight": "400"
    }
  }, [_c('label', {
    staticStyle: {
      "color": "#E5432E",
      "font-weight": "400"
    }
  }, [_vm._v("已返" + _vm._s(_vm.product.moneyAmount) + "元红包")])]) : _vm._e()]), _c('div', {
    staticClass: "zt"
  }, [_c('label', {
    staticClass: "lb1",
    class: {
      'yk': _vm.product.userReceiveStatus == 1
    }
  }, [_vm._v(_vm._s(_vm.product.userReceiveStatus == 1 ? "已看" : "未看"))]), _c('label', {
    staticClass: "lb2",
    class: {
      'yk': _vm.product.userReceiveStatus == 1
    }
  })])]), _c('img', {
    staticClass: "img-cover",
    attrs: {
      "src": _vm.product.coverImgageUrl
    }
  }), _c('div', {
    staticClass: "receive-box v2-g-flex-row"
  }, [_c('p', {
    staticClass: "money money1"
  }, [_vm._v(" 已观看"), _c('span', {
    staticStyle: {
      "color": "#E5432E"
    }
  }, [_vm._v(_vm._s(_vm.product.playFinishUsersTotal) + "人")]), _vm._v(" 已下单"), _c('span', {
    staticStyle: {
      "color": "#E5432E"
    }
  }, [_vm._v(_vm._s(_vm.product.customCount))]), _vm._v("家 ")]), _c('span', {
    staticClass: "like",
    class: _vm.product.likeStatus == 1 ? 'sel' : '',
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.like.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "iconfont icon-like"
  }), _vm._v(_vm._s(_vm.product.likeNumber))])]), _vm._m(0), _vm._l(_vm.product.productCommonVOList, function (item, i) {
    return _c('ProductItem', {
      key: i,
      attrs: {
        "product": item,
        "count": _vm.product.customCount
      },
      on: {
        "hover": _vm.hover
      }
    });
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "relation-product"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets-v2/images/img_adorn.png")
    }
  }), _vm._v(" 相关商品 ")]);
}];
export { render, staticRenderFns };