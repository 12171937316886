var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticStyle: {
      "margin-top": "10px",
      "height": "calc(100% - 80px)"
    }
  }, [_c('div', {
    staticClass: "tips"
  }, [_vm._v("商品包装和说明书以实物为准，平台内容仅供参考。")]), _vm.content ? _c('div', {}, [_c('span', {
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.content))])]) : _c('div', {
    staticClass: "empty"
  }, [_c('div', {}, [_vm._v("暂无商品说明")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cate-item-content"
  }, [_c('div', {
    staticClass: "active_logo"
  }), _c('span', [_vm._v("商品说明书")])]);
}];
export { render, staticRenderFns };