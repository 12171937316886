<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>
        <div class="v2-g-w1200">
            <div class="v-title">
                <img src="../../assets-v2/images/v-redbag-bg.png" />
            </div>
            <div class="v-list v2-g-flex-row" v-if="videoList && videoList.length > 0">
                <div class="v-left">
                    <div class="v2-g-flex-row v-title">

                        <div class="videoList-title-detail">
                            <div class="videoList-title-detail-msg">
                                <div class="num">{{ videoSummary.videoCount }}</div>
                                <div class="title">视频数</div>
                            </div>
                            <div class="videoList-title-detail-msg">
                                <div class="num">{{ videoSummary.videoCount - videoSummary.totalViewCount }}</div>
                                <div class="title">未看</div>
                            </div>
                            <div class="videoList-title-detail-msg">
                                <div class="num"><label>￥</label>{{ videoSummary.totalToGetAmount ? videoSummary.totalToGetAmount.toFixed(2) : 0.00 }}</div>
                                <div class="title">可领取</div>
                            </div>

                            <div class="videoList-title-detail-msg">
                                <div class="num"><label>￥</label>{{ totalBalanceAmount ? totalBalanceAmount.toFixed(2) : 0.00 }}
                                </div>
                                <div class="title">红包余额</div>
                            </div>
                        </div>

                    </div>
                    <div class="video-title">
                        <div>
                            <b style="margin-left:10px;font-weight:400" v-if="currItem.userReceiveStatus != 1">看视频返<label
                                    style="color: #E5432E;;font-weight:400">{{ currItem.moneyAmount }}元</label>红包</b>
                            <b style="margin-left:10px;font-weight:400" v-if="currItem.userReceiveStatus == 1"><label
                                    style="color: #E5432E;;font-weight:400">已返{{ currItem.moneyAmount }}元红包</label></b>
                        </div>
                        <div class="zt">
                            <label class="lb1" :class="{ 'yk': currItem.userReceiveStatus == 1 }">
                              {{ currItem.userReceiveStatus == 1 ? "已看" : "未看" }}
                            </label>
                          <label class="lb2" :class="{ 'yk': product.userReceiveStatus == 1 }"></label>
                        </div>
                    </div>
                    <div>
                        <video :id="currItem.id" ref="myVideo" :poster="currItem.coverImgageUrl" :src="currItem.videoUrl"
                            @timeupdate="timeupdate" @ended="endedVideo" @play="playVideo()" :controls="true"
                            oncontextmenu="return false" controlslist="nodownload" class="video-box" playsinline=''
                            webkit-playsinline='' x5-playsinline='' x-webkit-airplay='allow'></video>
                    </div>
                    <div class="vedio-msg-box-le" v-if="currItem" :class="currItem.receiveStatus == 1 ? 'green' : ''">
                        已观看<span style="color: #E5432E">{{ currItem.playFinishUsersTotal }}人</span> 已下单<span
                            style="color: #E5432E">{{ currItem.customCount }}</span>家
                    </div>
                    <div class="left-relation v2-g-flex-column" v-if="false">
                        <div class="relation-product">
                            <img src="../../assets-v2/images/img_adorn.png" />
                            相关商品
                        </div>
                        <div class="v2-g-flex-column left-re"
                            v-if="currItem.productCommonVOList && currItem.productCommonVOList.length > 0">
                            <ProductItem :is-big-show="true" v-for="(item, index) of currItem.productCommonVOList" :key="index" :product="item">
                            </ProductItem>
                        </div>
                        <div v-else class="v2-g-flex-column left-re">
                            暂无相关产品
                        </div>
                    </div>
                </div>

                <div class="v-right" :style="{ 'height': rightHeight + 'px' }">
                    <template v-for="item of videoList">
                        <VideoItem :product="item" @play="playVideo(item)" @like="checkLike" @hover="hover"></VideoItem>
                        <div class="sep"></div>
                    </template>
                </div>
                <div class="detail" v-if="hoverShow" :style="{ 'left': left, 'top': top }">
                    <p class="name">{{ product.productName }}<span class="guige">【{{ product.specifications }}】</span>
                    </p>

                    <ul class="basic-info">
                        <li class="price">
                            <p v-if="product.preferenticePrice == ''">定价中</p>
                            <template v-else-if="getActivityPrice() > 0">
                                <p>活动价</p>
                                <p>￥{{ getActivityPrice().toFixed(2) }}/{{ product.sellUnit }}</p>
                            </template>
                            <template v-else>
                                <p>采购价格：</p>
                                <p>￥{{ product.preferenticePrice }}/{{ product.sellUnit }}</p>
                            </template>
                        </li>
                        <li>

                            <p>零售价</p>
                            <p> {{ product.suggestedPrice + '/' + product.sellUnit }}</p>
                        </li>
                        <li v-if="product.grossProfitRate">
                            <p>毛利率</p>
                            <p>{{ product.grossProfitRate }}%</p>
                        </li>
                        <li v-if="product.activityVOList && product.activityVOList.length > 0" class="actli">
                            <p>活动</p>
                            <div class="act-item">
                                <div class="act " v-for="(item, index) in product.activityVOList" :key="index">
                                    <span class="act-type" style="width:40px"
                                        :class="[item.type == 1 ? 'act-mj' : '', item.type == 2 ? 'act-fhb' : '']">{{
                                            item.type == 1 ? "立减" : item.type == "2" ? "红包" : "其他"
                                        }}</span>
                                    <span class="act-name">{{ item.activityName | replaceStr }}<i>({{
                                        item.type == 1 ?
                                        "减"
                                        : "返"
                                    }})</i>{{ item.activityName | replaceStr1 }}</span>
                                </div>
                            </div>

                        </li>
                        <li>
                            <p>包装</p>
                            <p>{{ product.sellUnit }}</p>
                        </li>
                        <li>
                            <p>件装</p>
                            <p> {{ product.packageNumber }}</p>
                        </li>
                        <li>
                            <p>生产厂家</p>
                            <p>{{ product.produceFactory }}</p>
                        </li>
                        <li>
                            <p>效期</p>
                            <p> {{ product.nearEffectivePeriod }} </p>
                        </li>
                        <li>
                            <p>最后退货时间</p>
                            <p>{{ product.lastReturnTime }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="v-list v2-g-flex-row" v-else>
                <div class="nodata">
                    <img src="https://obs.pujian.com/frontend/applet/index/default_page@2x.png" />
                    <span>暂无视频红包相关数据</span>
                </div>
            </div>
        </div>
        <PageBottom></PageBottom>
        <div class="msg-box" v-if="showBox">
            <div class="tip v2-g-flex-column">
                <div class="close" @click.stop="showBox = false">
                    <label class="iconfont icon-close" />
                </div>

                <img src="https://obs.pujian.com/frontend/app/other/20230421175003.png" />
                <div class="money">{{ currItem.moneyAmount.toFixed(2) }}<label style="font-size:12px">元</label></div>
                <div class="btn" @click.stop="showBox = false"></div>
            </div>
        </div>
    </div>
</template>
<script>
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import VideoItem from './video-item.vue'
import ProductItem from './product-item.vue'
import { kv } from "../../mixin/kv"
export default {
    components: { PageTop, PageBottom, PageTop1, VideoItem, ProductItem },
    mixins: [kv],
    data() {
        return {
            // 红包统计
            hb_count: {},
            totalBalanceAmount: 0,
            videoList: [],
            isLoadMoreVideo: false,
            pageNum: 1,
            pageSize: 30,
            // 视频音量
            videoVolume: 50,
            // 观看时长
            lastWatchTime: null,
            timer: null, // 定时器
            // 是否正在播放
            isPlaying: false,
            // 音量调节窗口显示
            isVolumeJustShow: false,
            currItem: {},
            hasWatchVideoArr: [],
            showBox: false,
            rightHeight: 0,
            product: {},
            hoverShow: false,
            left: 0,
            top: 0,
            videoSummary: {}
        }
    },
    created() {
        this.getHbCount();
        this.getHbTotalToday();
        this.getVideoList();
        this.calcLeftHeight();
        this.getVideoSummary();
        this.kv_visit('16');//视频红包
    },
    filters: {
        replaceStr(str) {
            let arr = str.split("-")
            return arr[0]
        },
        replaceStr1(str) {
            let arr = str.split("-")
            return arr[1]
        }
    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    methods: {
        hover(product, rect, flag) {
            this.product = product;
            this.hoverShow = flag;
            this.left = (rect.left - 450) + 'px';
            this.top = rect.top + 'px';
        },
        calcLeftHeight() {
            this.rightHeight = 600;
        },
        playVideo(item) {
            this.showBox = false;
            if (item) this.currItem = item;
            if (this.currItem.haveYouAlreadyWatchedIt) {
              this.$toast.mount(this.$toast.create("您已看过该视频，无需重复观看~"));
              this.$refs.myVideo.load();
              return;
            }
            this.$refs.myVideo.play();
            this.operateDetail(this.currItem.id, 2);
        },
        endedVideo() {
          console.log('视频播放完毕')
          this.$refs.myVideo.load();
          this.operateDetail(this.currItem.id, 1, this.currItem.duration, () => {
            this.getReceive()
          });
        },
        // 获取当前播放位置
        timeupdate(e) {

            let jumpTime = this.$refs.myVideo.currentTime;

            if (this.lastWatchTime && jumpTime - this.lastWatchTime > 1) {
                this.$refs.myVideo.currentTime = this.lastWatchTime;
            } else {
                this.lastWatchTime = jumpTime
            }
        },

        //获取当日红包金额  新版
        async getHbCount() {
            let url = `/pjyy-deepexi-market-center/api/v1/up/mobile/video/count`;
            let res = await this.$getHttpClient().get(url, { "headers": { "TokenType": 'customer' } });
            if (res.data.code == 200) {
                this.hb_count = res.data.data;
            }
        },
        //获取视频汇总信息
        async getVideoSummary() {
            let api = `/pjyy-deepexi-market-center/api/v1/up/mobile/video/getVideoTotalInfo`;
            const res = await this.$getHttpClient().get(api, { "headers": { "TokenType": 'customer' } })

            if (res.data.code == 200) {
                this.videoSummary = res.data.data
            }
        },
        //获取当日红包余额
        async getHbTotalToday() {
            let data = {
                page: 1,
                pageSize: 20
            }
            let url = '/pjyy-deepexi-user-center/api/v1/balance/pageList';
            let res = await this.$getHttpClient().post(url, data);
            if (res.data.code == 200) {
                this.totalBalanceAmount = res.data.data.totalBalanceAmount;
            }
        },
        // 获取视频列表
        async getVideoList(refresh = false) {
            // 加载下一页时，禁用滚动触底事件
            this.isLoadMoreVideo = false;
            let pageNum = refresh ? 1 : this.pageNum++;
            if (refresh) {
                this.videoList = []
                // 刷新页面时，同步刷新红包金额统计数据
                this.getHbCount();
                this.getHbTotalToday();
            }
            let url = `/pjyy-deepexi-market-center/api/v1/up/mobile/video/list?page=${pageNum}&pageSize=${this.pageSize}`;
            let res = await this.$getHttpClient().get(url, { "headers": { "TokenType": 'customer' } });
            if (res.data.code == 200) {
                const videoList = res.data.data.list;
                this.videoList = this.videoList.concat(videoList);
                if (this.videoList && this.videoList.length > 1) {
                    this.currItem = this.videoList[0]
                }
                // 当前视频列表集合小于总数时，可继续往下滑动
                this.isLoadMoreVideo = this.videoList.length < res.data.data.total;
            }
        },
        // 领取红包
        async getReceive() {
            if (this.currItem.userReceiveStatus != 3) {
                // 已领取红包或人数已达上限则不调用领红包逻辑
                return;
            }
            // 判断本次页面中是否已经领取过该视频， 避免重复提交
            for (let i = 0; i < this.hasWatchVideoArr.length; i++) {
                if (this.hasWatchVideoArr[i] == this.currItem.id) {
                    return;
                }
            }
            let url = '/pjyy-deepexi-task/api/v1/money/addV2';
            let res = await this.$getHttpClient().post(url, {
                businessId: this.currItem.id,
                direction: 1,   //资金方向【1收入-1支出】 不能为空
                channel: 2, //资金途径【1拼团返现2视频红包】不能为空
                setupNumber: 0, //阶梯序号
                unitPrice: 0, //返现单价
                totalNumber: 0, //总数量
                totalAmount: 0.00,//本次红包总金额 不能为空
                duration: this.currItem.duration,
            });
            if (res.data.code == 200) {
                this.hasWatchVideoArr.push(this.currItem.id);
                this.showBox = true;
                this.currItem.userReceiveStatus = 1;
                this.getHbCount();
                this.getHbTotalToday();
                this.getVideoSummary();
            }
        },
        //点赞和取消点赞
        async checkLike(item) {
            if (item.likeStatus == 1) {
                item.likeStatus = 0;
                item.likeNumber -= 1;
                await this.delLike(item.id);
            } else {
                item.likeStatus = 1;
                item.likeNumber += 1;
                await this.operateDetail(item.id, 4);
            }
        },

        //取消点赞
        async delLike(id) {
            this.$getHttpClient().post('/pjyy-deepexi-market-center/api/v1/up/mobile/operate/detail/cancel', {
                videoRewardId: id,
                operateMode: 4
            })
        },
        // 记录用户行为
        async operateDetail(videoId, type, langTime, callback) {
            let data = {
                operateMode: type,  // 1是领红包，2是看视频，3是浏览，4是点赞，5是分享
                videoRewardId: videoId,
            }
            if (type === 1) {
                data.playDuration = langTime;
            }
            await this.$getHttpClient().post('/pjyy-deepexi-market-center/api/v1/up/mobile/operate/detail', data, { "headers": { "TokenType": 'customer' } });
            if (type == 1) {
              this.currItem.haveYouAlreadyWatchedIt = true;
            }
            callback && callback()
        },
        //获取活动价格
        getActivityPrice() {

            let price = 0;
            if (this.product.activityVOList && this.product.activityVOList.length > 0) {
                let filter = this.product.activityVOList.find(t => t.type == 1);
                if (filter != undefined) {
                    price = filter.activityZhPrice;
                }
            }
            return price;
        },
    }

}
</script>
<style scoped lang="less">
.v-title {

    position: relative;


    img {
        position: absolute;
        z-index: -1;
        top: -50px;
        left: 0;
        width: 100%;
    }

    .t {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 28px;
        margin-right: 20px;


        p {
            position: relative;
            font-weight: 500;
            font-family: Source Han Sans CN;
        }

        p::before {
            content: "";
            position: absolute;
            width: 41px;
            height: 41px;
            top: 0;
            left: -21px;
            background: url("../../assets-v2/images/yuan1.png")no-repeat;
        }

        p::after {
            content: "";
            position: absolute;
            width: 11px;
            height: 11px;
            bottom: 5px;
            right: -4px;
            background: url("../../assets-v2/images/yuan2.png")no-repeat;
        }
    }

    .content-box {
        justify-content: center;

        .box-show {
            color: #fff;
            width: 120px;
            height: 50px;
            justify-content: center;
            font-size: 12px;
            background: #3875F6;
            box-shadow: 0px 4px 7px 0px rgba(89, 140, 247, 0.4);
            border-radius: 10px;
            margin-right: 30px;

            span {
                &:last-child {
                    font-size: 20px;
                    line-height: 20px;
                }
            }

            .yang {
                font-size: 13px;
            }
        }

        .box1 {
            background: #FF7209;
            box-shadow: 0px 4px 7px 0px rgba(255, 114, 9, 0.4);
        }
    }
}

.videoList-title-detail {
    width: 100%;
    padding: 0 12px;
    height: 77px;
    background: linear-gradient(180deg, #FFBA6B 0%, #FF5741 100%);
    border-radius: 12px 12px 12px 12px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .videoList-title-detail-msg {
        font-size: 15px;
        color: #c8b274;
        display: flex;
        flex-direction: column;
        align-items: center;

        .num {
            font-size: 20px;
            color: #fff;
            font-weight: 600;
            line-height: 28px;

            label {
                display: inline-block;
                //transform: scale(0.8);
                font-size: 14px;
            }
        }

        .title {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffffb5;
            line-height: 20px;

        }
    }
}

.nodata {
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 300px;
    }

    span {
        padding-top: 20px;
        color: #999999;
    }
}

.v-list {
    margin-top: 20px;
  //  z-index: 100;
    position: relative;

    .v-left {


        width: 870px;
        padding: 0px 30px;

        .video-title {
            margin-top: 30px;

            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            text-align: left;
            display: flex;

            .zt {
                color: #E5432E;
                position: relative;
                padding: 0 2px;
                margin-left: 10px;

                .lb1 {
                    font-weight: 400;

                    &.yk {
                        color: rgba(0, 0, 0, 0.6);
                    }

                }

                .lb2 {
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 36px;
                    height: 4px;
                    background: #E5432E;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 0.1;

                    &.yk {
                        background: rgba(0, 0, 0, 0.6);
                    }
                }
            }
        }

        .video-box {
            width: 100%;
        }

        .vedio-msg-box-le {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-left: 5px;
        }

        .relation-product {
            margin-top: 56px;
            margin-bottom: 35px;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #222222;
            position: relative;

            img {
                height: 20px;
            }
        }

        .left-relation {

            align-items: center;
        }

        .left-re {
            border: 1px solid #EDEDED;
            border-radius: 14px;
            align-items: center;
            padding: 31px 41px 0 41px;
            width: 552px;
            margin-top: 10px;
            color: #999999;
        }
    }

    .v-right {

        width: calc(100% - 870px);
        overflow-y: auto;
        height: 620px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .sep {

            width: 100%;
            margin-top: 20px;
        }



    }
}

.detail {


    position: fixed;
    right: auto;
    width: 450px;
    padding: 12px 12px;
    // top: -10px;
    //left: calc(100% + 10px);
    font-size: .85rem;
    font-weight: 400;
    z-index: 38;
    background: #ffffff;
    color: rgba(0, 0, 0, .7);
    box-shadow: 0 1px 2px 1px rgb(0 1 0 / 20%);

    .name {
        font-size: 1rem;
        text-align: left;
        font-weight: 600;
    }

    .price {
        font-size: 0.875rem;
    }

    .act~.act {
        background: #FFF4D7;
    }

    .act {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background: #FFF3F3;
        margin-top: 1px;
        border-radius: 9px;

        .act-type {
            width: 40px;
            height: 21px;
            display: inline-block;
            color: #ffffff;
            font-size: 0.65rem;
            border-radius: 9px 0px 9px 9px;
            text-align: center;
            line-height: 21px;
        }

        .act-mj {
            background: linear-gradient(0deg, #FE4B4F 0%, #FEA8C1 100%);

        }

        .act-fhb {

            background: linear-gradient(0deg, #FC664C 0%, #FCB466 100%);
        }

        .act-name {
            font-size: 12px;

            margin-left: 5px;

            i {
                color: #E5432E;
                font-style: normal;
            }
        }
    }

    .basic-info {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 10px 10px 10px;

        li {
            display: flex;
            height: 30px;
            line-height: 30px;
            width: 100%;

            color: #666666;

            &.price {
                p {
                    font-weight: 400;

                    &:nth-child(2) {
                        color: #E5432E
                    }
                }
            }

            p {

                &:first-child {
                    width: 85px;
                    text-align: left;
                }
            }

            &.actli {
                height: inherit;
                line-height: inherit;
            }

            :last-child,
            .act {
                margin-left: 10px;
            }

            .act-item {
                .act {
                    margin-left: 0;
                }
            }
        }
    }
}

.msg-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5000;
    background: #00000040;

    .tip {
        width: 270px;
        height: 281rpx;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        border-radius: 10px;
        align-items: center;
        justify-content: center;

        .close {
            position: absolute;
            right: -20px;
            top: -20px;
            color: #ece9e9;
            font-weight: 600;
            cursor: pointer;

            .icon-close {
                font-size: 24px;
            }
        }

        .btn {
            position: absolute;
            bottom: 28px;
            left: 30px;
            width: 210px;
            height: 44px;
        }

        .money {
            position: absolute;
            top: 110px;
            left: 35px;
            font-weight: bold;
            color: #FFFFFF;
            height: 32px;
            line-height: 32px;
            font-size: 30px;
            font-family: Source Han Sans CN;
        }

        img {
            width: 270px;
            height: 281rpx;
        }

    }
}</style>
