<template>
    <div class="p-item-wrapper " ref="p-wrapper" @click.stop="jumpdetail" @mouseover.stop="hover(true)" @mouseleave="hover(false)">


        <div class="v2-g-flex-row" style="align-items: flex-start;">
            <div class="pic" :style="{ 'width': isBigShow ? '204px' : '90px' }">

                <!-- 医院标签 -->
                <div class="tag-top-right" v-if="product.hospitalClassificationValue == '1'">医院</div>
                <div class="pic-center">
                    <img :src="product.mainImageUrl" />
                </div>

                <!-- 无货，失效 -->
                <div class="center" v-if="product.availableNum <= 0 || !product.isInvalid">
                    <div v-if="product.availableNum <= 0" class="bj">已售罄</div>
                    <div v-else-if="!product.isInvalid" class="bj">失效</div>
                </div>
                <!-- 无货，失效 -->
                <div class="center" v-if="product.kcName == '库存不足'">
                    <div class="noavaible">库存不足</div>
                </div>
                <div :class="['rgPs', product.isLearning && product.isLearning === 1 && product.areaExclusiveProductCategory === 0 ? 'xsPs' : '']" v-if="product.areaExclusiveProductCategory === 1 || (product.isLearning && product.isLearning === 1)">
                    {{ product.areaExclusiveProductCategory === 1 ? '区域独家' : product.isLearning && product.isLearning === 1 ? '学术' : '' }}
                </div>
                <!-- 返利 0差价标签 -->
                <div class="tag-bottom-right" v-if="product.isZeroPriceDifference || product.productAttribute == '1'"
                    :class="[product.isZeroPriceDifference ? 'lcj' : '', product.productAttribute == '1' ? 'fy' : '']">
                    <div v-if="product.isZeroPriceDifference" class="c1">0</div>
                    <div v-if="product.isZeroPriceDifference" class="c">差价</div>
                    <div v-else-if="product.productAttribute == '1'" class="c">返利</div>
                </div>
                <!-- 缺货，库存不足 -->
                <div class="bottom" v-if="product.availableNum > 0 && product.availableNum < 100">
                    <span v-if="product.isWillNotHave == 1">仅剩{{ product.availableNum }}{{
                        product.sellUnit
                    }},即将缺货</span>
                    <span v-else>仅剩{{ product.availableNum }}{{ product.sellUnit }}</span>
                </div>
                <div class="buy" v-if="product.buycs">
                    <span>已购<label>{{ product.buycs }}次</label></span>
                </div>
                <div class="purchase" style="margin-top:10px;bottom:-50px" v-if="count">
                    <span >已下单<label>{{ count }}家</label></span>
                </div>
            </div>
            <div class="attr v2-g-flex-column">
                <div class="product-name">
                    <span v-if="product.productNameHighlight" v-html="product.productNameHighlight"></span>
                    <span v-else> {{ product.productName }}</span>
                    <span class="guige">【{{ product.specifications }}】</span>
                </div>
                <productLabels :itemObject="product"/>
                <div class="act " v-for="(item, index) in product.activityVOList" :key="index">
                    <span class="act-type" :class="[item.type == 1 ? 'act-mj' : '', item.type == 2 || item.type == 4 ? 'act-fhb' : '']">{{
                        item.type == 1 ? "立减" : "红包"
                    }}</span>
                    <span class="act-name">{{ item.activityName | replaceStr }}<i>{{
                        item.type == 1 ? "减" : "返"
                    }}</i>
                        {{ item.activityName | replaceStr1 }}</span>
                </div>
                <div class="company ">
                    <span v-if="product.produceFactoryHighlight" v-html="product.produceFactoryHighlight"></span>
                    <span v-else>{{ product.produceFactory }}</span>
                </div>
                <div class="yxq ">
                    <span>效期:{{ product.nearEffectivePeriod }}</span>
                </div>
                <div class="thsj " v-if="product.lastReturnTime">
                    <span>最后退货时间:{{ product.lastReturnTime }}</span>
                </div>
                <div class="lsj v2-g-flex-row">

                    <span> 零售价:{{ product.suggestedPrice + '/' + product.sellUnit }}</span>

                </div>

                <div class="price">
                    <span v-if="product.preferenticePrice == ''">定价中</span>
                    <span v-else-if="getActivityPrice() > 0">活动价￥{{ getActivityPrice().toFixed(2) }} <span class="del">￥{{
                        product.preferenticePrice
                    }}</span></span>
                    <span v-else>￥{{ product.preferenticePrice }}/{{ product.sellUnit }}</span>
                </div>
                <div class="similar v2-g-flex-row " @click.stop="showInstruction = true">
                    <span class="v2-g-flex-row">商品说明书</span>
                    <span style="float:right;font-weight: 600;line-height: 18px;"><i
                            class="iconfont icon-arrow-right"></i></span>
                </div>
                <div class="greens v2-g-flex-row " style="margin-top:0;margin-bottom:3"
                     v-if="!isShowSimilar1 && product.productAliasCount > 0" @click.stop="showSimilarDlg(product)">
                  <span>{{ product.productAliasCount }}种同类产品</span>
                  <span style="font-weight: 600;line-height: 18px;">
                    <i class="iconfont icon-arrow-right"></i>
                  </span>
                </div>
                <div class="num-real"
                    v-if="product.isMediumPackage == 1 && product.mediumPackage && product.mediumPackage > 0 && isBigShow">
                    实际购买{{ product.mediumPackage * buyCount }}{{ product.sellUnit }}</div>
                <!-- 购物车 -->
                <div class='card-wrapper v2-g-flex-row ' v-if="isBigShow">
                    <div class="add-card v2-g-flex-row "
                        :class="product.availableNum <= 0 || !product.isInvalid ? 'disabled' : ''"
                        v-if="product.availableNum > 0">
                        <div class="num v2-g-flex-column ">

                            <div>
                                <a class="op" href="javascript:;" @click.stop="addCount(-1)"><i
                                        class="iconfont icon-sami-select"></i></a>
                                <input type="number" v-model="buyCount" ref='ballnum' @click.stop="" />
                                <a class="op" href="javascript:;" @click.stop="addCount(1)"><i
                                        class="iconfont icon-add-select"></i></a>
                            </div>

                        </div>
                        <button class="btn" type="button" @click.stop="addCard()">
                            <img src="@/assets-v2/images/sy_gouwuche.png" style="width:16px;" />
                            <span>加入购物车</span>
                        </button>

                    </div>
                </div>
            </div>

        </div>
        <div class="num-real"
            v-if="product.isMediumPackage == 1 && product.mediumPackage && product.mediumPackage > 0 && !isBigShow">
            实际购买{{ product.mediumPackage * buyCount }}{{ product.sellUnit }}</div>
        <!-- 购物车 -->
        <div class='card-wrapper v2-g-flex-row ' v-if="!isBigShow">
            <div class="add-card v2-g-flex-row " :class="product.availableNum <= 0 || !product.isInvalid ? 'disabled' : ''"
                v-if="product.availableNum > 0">
                <div class="num v2-g-flex-column ">

                    <div>
                        <a class="op" href="javascript:;" @click.stop="addCount(-1)"><i
                                class="iconfont icon-sami-select"></i></a>
                        <input type="number" v-model="buyCount" ref='ballnum' @click.stop="" />
                        <a class="op" href="javascript:;" @click.stop="addCount(1)"><i
                                class="iconfont icon-add-select"></i></a>
                    </div>

                </div>
                <button class="btn" type="button" @click.stop="addCard()">
                    <img src="@/assets-v2/images/sy_gouwuche.png" style="width:16px;" />
                    <span>加入购物车</span>
                </button>

            </div>
        </div>
        <Dialog title="商品说明书" :show="showInstruction" width="600px" height="600px" @close="closeIns">
            <Instruction :product-code="product.productId.toString()"></Instruction>
        </Dialog>
        <Dialog title="同类商品" :show="isShowSimilar1" width="1250px" height="80%" @close="closeSimilar">
            <SimilarList :id="productAliasCode" :productCode="product.productCode"></SimilarList>
        </Dialog>

      <!--过期/临期资质弹窗-->
      <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    </div>
</template>
<script>
import { AccountStorage } from "@services/account";
import { mapActions } from "vuex";
import Instruction from './instruction.vue'
import Dialog from '@/components-v2/v2-dialog/index'
import { kv } from "../../mixin/kv"
import mixin from "@/components-v2/product/mixin";
import qualificationsPopup from '@mixin/qualificationsPopup'
import SimilarList from '@/components-v2/product/similar-product-list'
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'
import ProductLabels from "@/components-v2/product/productLabels.vue";

const accountStorage = new AccountStorage();
export default {
    mixins: [kv, qualificationsPopup,mixin],
    data() {
        return {
            buyCount: 1,
            showInstruction: false,
            isShowSimilar1:false,
            productAliasCode: ''
        };
    },
    components: {ProductLabels, qualificationsDialog, Dialog, Instruction,SimilarList },
    props: {
        product: {
            type: Object
        },
        count: { type: Number },
        isBigShow: { type: Boolean, default: false }
    },
    created() {
        this.defaultBuyNum();
    },
    filters: {
        replaceStr(str) {
            let arr = str.split("-")
            return arr[0]
        },
        replaceStr1(str) {
            let arr = str.split("-")
            return arr[1]
        }

    },
    methods: {
        ...mapActions([
            'findCardList',
            'updateCard',
            'setIsAddCard'
        ]),
        closeIns(){
            this.showInstruction=false;
            if (this.$refs['p-wrapper']) {
              let rect = this.$refs['p-wrapper'].getBoundingClientRect();
              this.$emit('hover', this.product, rect, false)
            }
        },
        closeSimilar() {
          this.isShowSimilar1 = false;
          if (this.$refs['p-wrapper']) {
            let rect = this.$refs['p-wrapper'].getBoundingClientRect();
            this.$emit('hover', this.product, rect, false)
          }
        },
        hover(flag) {
          if (this.$refs['p-wrapper']) {
            let rect = this.$refs['p-wrapper'].getBoundingClientRect();
            this.$emit('hover', this.product, rect, flag)
          }
        },
        showSimilarDlg(item) {
          this.productAliasCode = item.productAliasCode;
          this.isShowSimilar1 = true;
        }
    }
}
</script>
<style scoped lang="less">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}
.p-item-wrapper {
    position: relative;
    .pic {

        width: 90px;
        // height: 120px;
        position: relative;
        // overflow: hidden;
        font-size: .85rem;
        font-weight: 400;
        //  background: #FAFAFA;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;


        .purchaseTimes {
            position: absolute;
            top: -40px;
            left: -40px;
            font-size: 12px;
            width: 80px;
            height: 80px;
            line-height: 140px;
            background-color: #E6854B;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            /* border-top-right-radius: 100px; */
            /* border-top-left-radius: 100px; */
            -webkit-transform-origin: center;
            transform-origin: center;
            color: #ffffff;
            z-index: 11;
            text-align: center;
        }

        .pic-center {

            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                margin: 0px auto;
                width: 100%;
                height: 100%;
                display: block;
            }

        }

        .tag-top-right {
            position: absolute;
            top: 0;
            right: 0;

            width: 35px;
            height: 27px;
            background: #00B0FF;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 27px;
        }

        .tag-bottom-right {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 43px;
            height: 42px;

            border-radius: 38px 0px 4px 0px;

        }


        .lcj {
            background: linear-gradient(0deg, #1B45F3 0%, #61C6FB 100%);
            color: #ffffff;
            width: 35px;
            height: 38px;

            .c {
                position: absolute;
                bottom: 1px;
                right: 2px !important;
                font-weight: 600;
                font-size: 12px;
            }

            .c1 {
                color: #fff;
                margin-top: 0px;
                padding: 0 5px;
                text-align: right;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .fy {

            background: linear-gradient(0deg, #077CA0 0%, #4CCFD0 100%);
            width: 30px;
            height: 30px;

            .c {
                position: absolute;
                bottom: 2px;
                right: 2px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 600;
            }
        }

        .center {
            width: 100%;
            height: 100%;
            background: rgb(0 0 0 / 30%);

            top: 0;
            position: absolute;
            z-index: 10;

            .bj {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60px;
                height: 60px;
                background: rgba(0, 0, 0, 0.32);
                border-radius: 50%;
                /* margin: 0px auto; */
                transform: translate(-50%, -50%);
                color: #ffffff;
                text-align: center;
                line-height: 60px;
                font-size: 0.75rem;
                font-weight: 600;
            }

            .noavaible {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100px;
                height: 100px;
                background: rgba(0, 0, 0, 0.32);
                border-radius: 50%;
                /* margin: 0px auto; */
                transform: translate(-50%, -50%);
                color: #ffffff;
                text-align: center;
                line-height: 100px;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 32px;
            background: rgb(0 0 0 / 30%);

            border-radius: 0px 0px 4px 4px;
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            line-height: 32px;

        }
        .purchase{
            position: absolute;
            bottom: -30px;
            left: 0;
            width: 100%;
            background-color: #E0EEFB;
            color: #3875F6;
            border-radius: 10px;
            font-size: 12px;
        }
        .buy {
            position: absolute;
            bottom: -30px;
            left: 0;
            width: 100%;
            background-color: #efefef;
            color: #3875F6;
            border-radius: 10px;
            font-size: 12px;
        }
    }
    &:hover  {
        .detail{
            display: block;
        }


}
    .attr {
        width: 160px;
        text-align: left;
        margin-left: 10px;
        justify-content: center;
        position: relative;

        .product-name {

            text-overflow: ellipsis;
            font-size: 0.875rem;
            font-weight: 400;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            height: 20px;
            line-height: 20px;
            color: #222;
            font-family: Source Han Sans CN;

            .jyb {
                display: inline-block;
                line-height: 18px;
                width: 18px;
                height: 18px;
                background: #3875F6;
                border-radius: 2px;
                color: #ffffff;
                text-align: center;
                font-size: 11px;
            }

            .guige {
                color: #666666;
                font-size: 12px;
            }
        }

        .margin11 {
            margin-top: 9px;
        }

        .act~.act {
            background: #FFF4D7;
        }

        .act {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            background: #FFF3F3;
            margin-top: 1px;
            border-radius: 9px;

            .act-type {
                width: 40px;
                height: 21px;
                display: inline-block;
                color: #ffffff;
                font-size: 0.65rem;
                border-radius: 9px 0px 9px 9px;
                text-align: center;
                line-height: 21px;
            }

            .act-mj {
                background: linear-gradient(0deg, #FE4B4F 0%, #FEA8C1 100%);

            }

            .act-fhb {

                background: linear-gradient(0deg, #FC664C 0%, #FCB466 100%);
            }

            .act-name {
                font-size: 12px;

                margin-left: 5px;

                i {
                    color: #E5432E;
                    font-style: normal;
                }
            }
        }

        .price {
            font-family: Source Han Sans CN;
            font-weight: 600;
            color: #E5432E;
            text-align: left;

            .del {
                color: #999999;
                text-decoration: line-through;
            }
        }

        .ybbm {
            font-size: 12px;
            font-weight: 400;
            height: 20px;
            line-height: 20px;
            display: flex;

            .bm {
                max-width: 180px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .fz {
                color: #3875F6;

            }
        }



        .company,
        .yxq {
            color: #666666;
            font-weight: 400;
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .lsj {
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
        }

        .thsj {
            height: 20px;
            line-height: 20px;
            font-size: 0.875rem;
            font-weight: 400;
            color: #3875F6;

        }


        .zbz,
        .ns-price {
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
        }

        .similar {
            padding: 0 5px;
            height: 18px;
            margin-top: 5px;
            margin-bottom: 3px;
            justify-content: space-between;
            background: #E2F7FF;
            border-radius: 9px;
            font-weight: 400;
            color: #3875F6;
            font-size: 12px;
            line-height: 18px;
        }
        .greens{
            padding: 0 5px;
            height: 18px;
            margin-top: 5px;
            margin-bottom: 3px;
            justify-content: space-between;
            background: linear-gradient(89deg, #D3FED3 0%, #F7FFF0 100%);
            border-radius: 9px;
            font-weight: 400;
            color: #5A9835;
            font-size: 12px;
            line-height: 18px;
        }
    }



    .num-real {
        line-height: 12px;
        font-size: 0.5rem;
        height: 12px;

        color: #FE5257;
    }

    .card-wrapper {
        justify-content: flex-end;
        margin-bottom: 15px;
    }

    .add-card {
        justify-content: flex-end;
        width: 200px;
        height: 34px;

        border: 1px solid #EDEDED;
        border-radius: 17px;


        .btn {
            display: flex;
            align-items: center;
        }

        &.disabled {
            color: grey;

            .btn,
            input {
                color: #CCCCCC;
                pointer-events: auto;
            }

            img {

                filter: #CCCCCC;
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
            }

        }

        .num {
            padding: 0 10px;
            justify-content: space-around;

            input {
                display: inline-block;
                width: 40px;
                height: 100%;
                border: none;
                text-align: center;
            }


        }

        .op {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: #F5F7F9;
            text-align: center;
            line-height: 18px;
            vertical-align: middle;
            text-decoration: none;
        }

        .btn {
            cursor: pointer;
            color: #3875F6;
            border: none;
            border-top-right-radius: 17px;
            border-bottom-right-radius: 17px;
        }


    }
  .rgPs{
    color: #ffffff;
    background: linear-gradient( 90deg, #FFAAB8 0%, #FF004E 100%);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 12px;
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 37;
    height: 20px;
    line-height: 20px;
  }
  .xsPs{
    background: linear-gradient( 90deg, #EB6F41 0%, #E03B29 100%);;
  }
}</style>
